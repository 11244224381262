<script lang="ts" setup>
	import { InputForm } from "~/utils/types";

	const props = defineProps<{
		id: string;
		action?: () => Promise<void>;
	}>();

	if (!props.id) {
		throw new Error("Form component requires an id prop");
	}

	// validation management ------------------------

	const object: InputForm = {};

	const form = useState(props.id + "-form", () => object);

	function validate(): void {
		Object.values(form.value).forEach((element) => {
			element.validating = true;
		});
	}

	const isValid = useState(props.id + "-isValid", () => false);

	watch(
		form,
		() => {
			const filtered = Object.values(form.value).filter((element) => {
				return !element.valid;
			});
			isValid.value = filtered.length ? false : true;
		},
		{ deep: true, immediate: true }
	);

	// processing management ------------------------

	const processing = ref(false);

	function submit() {
		validate();

		if (isValid.value && !processing.value && props.action) {
			processing.value = true;

			if (props.action) {
				props
					.action()
					.then((result) => {
						//processing.value = false;
					})
					.catch((error) => {
						//processing.value = false;
					})
					.finally(() => {
						processing.value = false;
					});
			}
		}
	}
</script>

<template>
	<div class="form" :class="[{ disabled: processing }]">
		<slot :form="form" :processing="processing" :submit="submit" :validate="validate" :isValid="isValid" />
	</div>
</template>

<style scoped lang="scss">
	.form {
		display: flex;
		flex-direction: column;
		gap: 10px;
		max-width: 100%;
		width: 100%;
		overflow: hidden;
		flex-wrap: wrap;
	}
</style>
